import React, { useState } from 'react';
import { Box, CircularProgress, Theme, Typography } from '@material-ui/core';

import { GetUserCouponUseCaseResult } from '../../ecosystems/hooks/get-coupon';
import { MasterHead } from '../molcules/MasterHead';
import { useTheme } from '@material-ui/core/styles';
import {
  SmartCheckExpiredTemplateExpiredTemplateWithTheme,
  SmartCheckOutOfDisplayTemplateWithTheme,
  SmartCheckUnavailableTemplateWithTheme,
  SmartCheckTemplate,
  SmartCheckViewProps,
  LayoutPattern,
  SmartCheckBeforeUseForm,
} from '@sbgift-coupon/use';
import { useClearCoupon } from '../../ecosystems/hooks/clear-coupon';
import { useForceCoupon } from '../../ecosystems/hooks/force-coupon';
import { useConfirmCoupon } from '../../ecosystems/hooks/confirm-coupon';

/**
 * SmartCheck用画面
 * SmartCheckコンシューマ向け画面では、画面遷移があるため各ステートを管理するコンポーネント
 * ステートによって、画面を切り替える（URLは変更されない）
 */
export const SmartCheck: React.FC<GetUserCouponUseCaseResult> = (props) => {
  const theme = useTheme<Theme>();

  // 確認画面を表示するかどうか
  const [confirm, setConfirm] = useState<boolean>(false);

  // カウントダウンが終了したかどうか
  const [countDownComplete, setCountDownComplete] = useState<boolean>(false);

  // APIから取得するクーポンデータ
  const [coupon, setCoupon] = useState<GetUserCouponUseCaseResult>(props);

  // コード消込時の消込コードが存在しない場合のエラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // SmartCheck確認API
  const {
    loading: confirmLoading,
    post: confirmPost,
    data: confirmData,
    response: confirmResponse,
  } = useConfirmCoupon(props.barcode.barcodeValue);

  // SmartCheck消込用API
  const { loading: clearLoading, error: clearError, post: clearPost } = useClearCoupon(props.barcode.barcodeValue);

  // SmartCheck強制終了API
  const { loading: forceLoading, error: forceError, post: forcePost } = useForceCoupon(props.barcode.barcodeValue);

  // 使用するボタンをクリックしたとき
  const onUseSubmit = async (form: SmartCheckBeforeUseForm): Promise<void> => {
    // 消込前に店舗が存在するかの検証するために確認APIを投げる
    await confirmPost({
      clearingCode: form.clearingCode,
    });

    if (confirmResponse.status === 404) {
      // 404の場合、消込コードが違うと判断しエラーメッセージを表示する
      setErrorMessage(`コードが存在しません[${form.clearingCode}]`);
    } else {
      setConfirm(true);
    }
  };

  // 確認画面で使用するボタンをクリックしたとき
  const onConfirmSubmit = async (): Promise<void> => {
    setConfirm(false);

    // 確認APIのレスポンスの消込コードを使って消込する
    if (confirmData) {
      const clearedData = await clearPost({
        memberShopCode: confirmData.memberShopCode,
        clearingCode: confirmData.clearingCode,
        status: 'USED',
      });
      setCoupon(clearedData);
    }
  };

  // 強制終了ボタンをクリックしたとき
  const onForceSubmit = async (): Promise<void> => {
    // 強制終了後に最新のクーポンデータを取得
    const forcedData = await forcePost({
      status: 'USED',
    });
    setCoupon(forcedData);
  };

  // キャンセルボタンをクリックしたとき
  const onCancelClick = (): void => {
    setErrorMessage('');
    setConfirm(false);
  };

  // 強制終了後 or 消込済み or props
  const viewProps: SmartCheckViewProps = {
    layoutPattern: props.layoutPattern as LayoutPattern,
    meta: { metaTags: coupon.meta, couponName: coupon.couponName },
    data: {
      organizationId: coupon.organizationId,
      couponMasterId: coupon.couponMasterId,
      id: coupon.id,
      applyWith: coupon.applyWith,
      couponCode: coupon.couponCode,
      couponName: {
        couponName: coupon.couponName,
        couponNameHtml: coupon.couponNameHtml,
        couponNameTextColor: coupon.couponNameTextColor,
        couponNameBandColor: coupon.couponNameBandColor,
      },
      organizationName: {
        companyName: coupon.organizationName,
        couponIssuerTextColor: coupon.couponIssuerTextColor,
        couponIssuerBandColor: coupon.couponIssuerBandColor,
      },
      companyImageUrl: coupon.companyImageUrl,
      barcode: coupon.barcode,
      description: {
        validityStartDateTime: coupon.validityStartDateTime,
        validityEndDateTime: coupon.validityEndDateTime,
        description: coupon.description,
        information: coupon.information,
        mapUrl: coupon.mapUrl,
        telNumber: coupon.telNumber,
        displayValidityDate: coupon.displayValidityDate,
      },
      smartCheckSetting: coupon.smartCheckSetting,
      smartCheckImageUrl: coupon.smartCheckImageUrl,
      smartCheckUsedAt: coupon.smartCheckUsedAt,
      smartCheckOrganizationName: confirmData?.shopName || props.smartCheckUsedOrganizationName || '',
      clearingCode: confirmData?.clearingCode || '',
    },
    available: {
      available: coupon.available,
      displayable: coupon.displayable,
      status: coupon.status,
      validity: coupon.validity,
    },
    handlers: {
      onUseSubmit,
      onConfirmSubmit,
      onCancelClick,
      onForceSubmit,
    },
    confirm,
    smartCheckCountDown: {
      countDownNow: coupon.smartCheckCountDownNow,
      countDownComplete,
      setCountDownComplete,
    },
    errorMessage,
    confirmLoading,
  };

  if (coupon.productImageUrl) {
    viewProps.data.productImage = {
      couponName: coupon.couponName,
      used: false,
      expired: coupon.validity === 'after',
      imageUrl: coupon.productImageUrl,
    };
  }

  // エラー
  if (confirmResponse.status >= 500 || forceError) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1">クーポンの表示に失敗しました</Typography>
      </Box>
    );
  }

  // 消し込みエラー
  if (clearError) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body1">
          クーポンの消し込みに失敗しました
          <br />
          ページを再度読み込んでください
        </Typography>
      </Box>
    );
  }

  // API実行中
  if (clearLoading || forceLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const { available, meta, data } = viewProps;

  // 利用不可 or キャンセルされたときはクーポンの表示はできない
  if (!available.available || available.status === 'CANCELED') {
    return (
      <>
        <MasterHead {...meta} />
        <SmartCheckUnavailableTemplateWithTheme theme={theme} {...data} />
      </>
    );
  }

  // 表示期間外
  if (available.displayable !== 'display') {
    const props = { ...data, displayable: available.displayable };
    return (
      <>
        <MasterHead {...meta} />
        <SmartCheckOutOfDisplayTemplateWithTheme theme={theme} {...props} />
      </>
    );
  }

  // 有効期限切れ
  if (available.validity === 'after') {
    return (
      <>
        <MasterHead {...meta} />
        <SmartCheckExpiredTemplateExpiredTemplateWithTheme theme={theme} {...data} />
      </>
    );
  }

  return (
    <>
      <MasterHead {...viewProps.meta} />
      <SmartCheckTemplate {...viewProps} />
    </>
  );
};
