import {
  AvailableTemplate,
  CouponViewProps,
  ExpiredTemplateWithTheme,
  LayoutPattern,
  OutOfDisplayTemplateWithTheme,
  UnavailableTemplateWithTheme,
  UsedTemplateWithTheme,
} from '@sbgift-coupon/use';
import { MasterHead } from '../molcules/MasterHead';
import React from 'react';
import { GetUserCouponUseCaseResult } from '../../ecosystems/hooks/get-coupon';
import { useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

/**
 * POS向け12桁バーコードテンプレート
 */
export const PosBarcode12: React.FC<GetUserCouponUseCaseResult> = (props) => {
  const theme = useTheme<Theme>();

  /**
   * ページの責務でビュー用データに変換する
   */
  const { layoutPattern, meta, data, available }: CouponViewProps = {
    layoutPattern: props.layoutPattern as LayoutPattern,
    meta: { metaTags: props.meta, couponName: props.couponName },
    data: {
      organizationId: props.organizationId,
      couponMasterId: props.couponMasterId,
      id: props.id,
      applyWith: props.applyWith,
      couponCode: props.couponCode,
      couponName: {
        couponName: props.couponName,
        couponNameHtml: props.couponNameHtml,
        couponNameTextColor: props.couponNameTextColor,
        couponNameBandColor: props.couponNameBandColor,
      },
      organizationName: {
        companyName: props.organizationName,
        couponIssuerTextColor: props.couponIssuerTextColor,
        couponIssuerBandColor: props.couponIssuerBandColor,
      },
      companyImageUrl: props.companyImageUrl,
      barcode: props.barcode,
      description: {
        validityStartDateTime: props.validityStartDateTime,
        validityEndDateTime: props.validityEndDateTime,
        description: props.description,
        information: props.information,
        mapUrl: props.mapUrl,
        telNumber: props.telNumber,
        displayValidityDate: props.displayValidityDate,
      },
    },
    available: {
      available: props.available,
      displayable: props.displayable,
      status: props.status,
      validity: props.validity,
    },
  };

  if (props.productImageUrl) {
    data.productImage = {
      couponName: props.couponName,
      used: props.status === 'USED',
      expired: props.validity === 'after',
      imageUrl: props.productImageUrl,
    };
  }

  // 利用不可
  if (!available.available) {
    return (
      <>
        <MasterHead {...meta} />
        <UnavailableTemplateWithTheme theme={theme} {...data} />
      </>
    );
  }

  // 表示期間外
  if (available.displayable !== 'display') {
    const props = { ...data, displayable: available.displayable };
    return (
      <>
        <MasterHead {...meta} />
        <OutOfDisplayTemplateWithTheme theme={theme} {...props} />
      </>
    );
  }

  // 有効期限切れ
  if (available.validity === 'after') {
    return (
      <>
        <MasterHead {...meta} />
        <ExpiredTemplateWithTheme theme={theme} {...data} />
      </>
    );
  }

  // 使用済み
  if (available.status === 'USED') {
    return (
      <>
        <MasterHead {...meta} />
        <UsedTemplateWithTheme theme={theme} {...data} />
      </>
    );
  }

  return (
    <>
      <MasterHead {...meta} />
      <AvailableTemplate theme={theme} layoutPattern={layoutPattern} {...data} />
    </>
  );
};
